import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../Layouts/index";
// import cover from "../../images/genetics-cover.jpg";
import Newsletter from "../../components/Newsletter";

import icon1 from "../../images/icon/icn1.png";
import icon2 from "../../images/icon/icn2.png";
import icon3 from "../../images/icon/icn3.png";
import icon4 from "../../images/icon/icn4.png";
import icon5 from "../../images/icon/icn5.png";
import icon6 from "../../images/icon/icn6.png";
import icon7 from "../../images/icon/icn7.png";

class Genetics extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-genetics common">
        <SEO title="Our genetics" />
        <div className="cover-container">
          <div className="cover-img">
            <Img
              fluid={this.props.data.cover.childImageSharp.fluid}
              className="img-fluid"
              alt="Cover Image"
            />
          </div>
          <div className="cover-content">
            <div className="wrap-hero">
              <h1 className="hero-title">SPKL Genetics</h1>
              {/* <h4 className="hero-sub-title">Our genetics program ensures the best, always</h4> */}
            </div>
          </div>
        </div>

        <div className="para-one text-center">
          <h2 className="title-2 color-primary text-uppercase">
            <div className="wrapper-m">
              {" "}
              <div className="title-m">
                {" "}
                With access to the best global Speckle Park genetics and proven
                results from top class Breeders...{" "}
              </div>
            </div>
          </h2>
          <div className="wrapper-bel">
            {" "}
            <p className="title-bel">
              {" "}
              ...our SPKL Genetics Program is world class. Our experienced
              cattlemen select genetics to assure only the best quality. State
              of the art collection facilities are also utilised, ensuring an
              investment in SPKL Genetics is a safe bet..
            </p>
          </div>
        </div>
        <div className="para-two text-center">
          <div className="wrapper-txt">
            <p className="mid-text">
              SPKL provides a wide variety of genetic selection for different
              breeds across all aspects of the cattle industry, and our loyalty
              program supports ongoing investment for breeders who are looking
              to drive even higher standards.
            </p>
          </div>
        </div>
        <div className="wrapper-pr">
          <div className="para-three">
            <h3 className="wrapper-txt pt-4">
              <div className="title-4 mid-text text-uppercase p-4">
                {" "}
                The Program Offers{" "}
              </div>
            </h3>
          </div>

          <div className="wrapper-bottom">
            <div className="container">
              <div className="row">
                <div className="gutter col-12 col-md-6">
                  <div className="divide d-flex">
                    <div className="icon">
                      <img alt="icon" className="img-fluid" src={icon1} />
                    </div>
                    <h5 className="title-6 color-white p-3">
                      High Quality Embryos
                    </h5>
                  </div>
                </div>

                <div className="gutter col-12 col-md-6">
                  <div className=" divide d-flex">
                    <div className="icon">
                      <img alt="icon" className="img-fluid" src={icon2} />
                    </div>
                    <h5 className="title-6 color-white p-3">
                      Import and Flushing Programs
                    </h5>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="gutter col-12 col-md-6">
                  <div className="divide d-flex">
                    <div className="icon">
                      <img alt="icon" className="img-fluid" src={icon3} />
                    </div>
                    <h5 className="title-6 color-white p-3">Semen Straws</h5>
                  </div>
                </div>

                <div className="gutter col-12 col-md-6">
                  <div className=" divide d-flex">
                    <div className="icon">
                      <img alt="icon" className="img-fluid" src={icon4} />
                    </div>
                    <h5 className="title-6 color-white p-3">
                      Traceability and DNA Tested Genetics
                    </h5>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="gutter col-12 col-md-6">
                  <div className="divide d-flex">
                    <div className="icon">
                      <img alt="icon" className="img-fluid" src={icon5} />
                    </div>
                    <h5 className="title-6 color-white p-3">
                      Year Round Flushing Availability
                    </h5>
                  </div>
                </div>

                <div className="gutter col-12 col-md-6">
                  <div className=" divide d-flex">
                    <div className="icon">
                      <img alt="icon" className="img-fluid" src={icon6} />
                    </div>
                    <h5 className="title-6 color-white p-3">
                      Access to Global Sales
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="gutter col-12 col-md-6  offset-md-3">
                  <div className="divide d-flex">
                    <div className="icon">
                      <img
                        alt="icon"
                        className="img-fluid gen-icon"
                        src={icon7}
                      />
                    </div>
                    <h5 className="title-6 color-white p-3">
                      Strict Health and Animal Welfare
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="p-md-5 col-12">
                  <div className="contact-box">
                    <div className="title-5 color-white px-4 pt-4 text-center">
                      GET IN TOUCH WITH the spkl team for more information ON
                      HOW YOU CAN BE A PART OF OUR PROGRAM
                    </div>
                    <div>
                      <a class="btn-secondary mt-1 mb-3" href="/contact">
                        CONTACT US
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Newsletter />
      </Layout>
    );
  }
}
export default Genetics;

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "genetics-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
